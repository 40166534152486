<template>
  <div class="topBar">
    <div class="textLogo">
      <TextLogo :textProps="textLogo.textProps" :image="textLogo.image" />
    </div>
    <div class="textGroup">
      <SideBarText
        class="text"
        :text="subText[0].text"
        :size="subText[0].size"
        :color="subText[0].color"
        :weight="subText[0].weight"
      />

      <SideBarText
        class="text"
        :text="subText[1].text"
        :size="subText[1].size"
        :color="subText[1].color"
        :weight="subText[1].weight"
      />
    </div>
  </div>
</template>

<script>
import TextLogo from "../TextLogo/TextLogo.vue";
import SideBarText from "../SideBarText/SideBarText.vue";

export default {
  name: "SideBarTop",
  props: {
    textLogo: {
      type: Object,
      required: true,
    },
    subText: {
      type: Array,
      required: true,
    },
  },
  components: {
    TextLogo,
    SideBarText,
  },
};
</script>

<style scoped>
.topBar {
  background-color: #000000;
  margin: 0px;
  padding: 0px;
}
.textLogo {
  display: flex;
  justify-content: center;
}
.text {
  padding-bottom: 3px;
}
@media screen and (min-width: 601px) {
  .topBar {
    width: 188px;
    height: 226px;
  }
  .textLogo {
    padding-top: 70px;
    padding-bottom: 50px;
  }
}
@media screen and (max-width: 600px), (max-height: 750px) {
  .topBar {
    width: 100%;
    height: 130px;
  }
  .textLogo {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .textGroup {
    padding-bottom: 10px;
  }
}
</style>
