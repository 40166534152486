<template>
  <div class="section">
    <div class="innerSection">
      <p class="introText">{{ introText }}</p>
      <p class="subText">{{ subText }}</p>
      <div class="scrollDown">
        <!-- <p class="scroll">Scroll down</p> -->
        <div id="wrapper">
          <div id="wrapper-inner">
            <div id="scroll-down">
              <span class="arrow-down">
                <!-- css generated icon -->
              </span>
              <span id="scroll-title"> Scroll down </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "IntroSection",
  props: {
    introText: {
      type: String,
      required: true,
      default: "Hi I'm Yusuf, a software developer",
    },
    subText: {
      type: String,
      default: "Back End Developer - Student",
    },
  },
};
</script>

<style scoped>
* {
  font-family: "Roboto", sans-serif;
}
p,
.section {
  margin: 0px;
}
.introText,
.subText {
  font-weight: 700;
  margin: 0px;
}
.introText {
  color: #ffffff;
}
.subText {
  color: #cfcfcf;
}
.section {
  background-color: #252627;
  background-image: url("../../assets/frame-square-800x800.svg");
  background-repeat: no-repeat;
  background-position: center center;
  width: 100%;
}

/*Tablet portrait*/
@media screen and (min-width: 601px) {
  .section {
    /* width: 100%; 188px because sidebar is 188px */
    height: 100%;
    background-size: 120% 120%;
  }
  .innerSection {
    padding: 0px 25px 0px 25px;
  }
  .introText {
    font-size: 48px;
    padding: 190px 0px 20px 0px;
  }
  .subText {
    font-size: 18px;
  }
  .scrollDown {
    padding-top: 580px;
    padding-bottom: 40px;
  }
}
@media screen and (min-width: 992px) {
  .section {
    /* width: 100%; 188px because sidebar is 188px  */
    height: 100%;
    background-size: 100% 100%;
  }
  .innerSection {
    padding: 0px 75px 0px 75px;
  }
  .introText {
    font-size: 64px;
    padding: 190px 0px 20px 0px;
    text-align: left;
  }
  .subText {
    font-size: 18px;
    text-align: left;
  }
  .scrollDown {
    padding-top: 580px;
    padding-bottom: 40px;
  }
}

/* Small and large phones*/
@media screen and (max-width: 600px), (max-height: 650px) {
  .section {
    /* width: 100%; 100% because sidebar is hidden as hamburger menu */
    height: 90vw;
    background-size: 100% 100%;
  }
  .innerSection {
    padding: 0px 25px 0px 25px;
  }
  .introText {
    font-size: 30px;
    padding: 75px 0px 20px 0px;
  }
  .subText {
    font-size: 16px;
  }
  .scrollDown {
    visibility: hidden;
    /* padding-top: 100px;
      padding-bottom: 10px; */
  }
}

html,
body {
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
}
#wrapper {
  display: table;
  width: 100%;
  height: 100%;
}

#wrapper-inner {
  display: table-cell;
  vertical-align: middle;
  width: 100%;
  height: 100%;
}

#scroll-down {
  display: block;
  position: relative;
  padding-top: 79px;
  text-align: center;
}
.arrow-down {
  display: block;
  margin: 0 auto;
  width: 10px;
  height: 38px;
}
.arrow-down:after {
  content: "";
  display: block;
  margin: 0;
  padding: 0;
  width: 8px;
  height: 8px;
  border-top: 2px solid #ffffff;
  border-right: 2px solid #ffffff;
  -moz-transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
  -o-transform: rotate(135deg);
  -ms-transform: rotate(135deg);
  transform: rotate(135deg);
}
#scroll-title {
  display: block;
  text-transform: uppercase;
  color: #ffffff;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.1em;
}
#scroll-down::before {
  -webkit-animation: elasticus 1.5s cubic-bezier(1, 0, 0, 1) infinite;
  /* Safari 4+ */

  -moz-animation: elasticus 1.5s cubic-bezier(1, 0, 0, 1) infinite;
  /* Fx 5+ */

  -o-animation: elasticus 1.5s cubic-bezier(1, 0, 0, 1) infinite;
  /* Opera 12+ */

  animation: elasticus 1.5s cubic-bezier(1, 0, 0, 1) infinite;
  /* IE 10+, Fx 29+ */

  position: absolute;
  top: 0px;
  left: 50%;
  margin-left: -1px;
  width: 2px;
  height: 90px;
  background: #ffffff;
  content: " ";
}
@-webkit-keyframes elasticus {
  0% {
    -webkit-transform-origin: 0% 0%;
    -ms-transform-origin: 0% 0%;
    -moz-transform-origin: 0% 0%;
    -o-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    -webkit-transform: scale(1, 0);
    -ms-transform: scale(1, 0);
    -moz-transform: scale(1, 0);
    -o-transform: scale(1, 0);
    transform: scale(1, 0);
  }
  50% {
    -webkit-transform-origin: 0% 0%;
    -ms-transform-origin: 0% 0%;
    -moz-transform-origin: 0% 0%;
    -o-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    -webkit-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }
  50.1% {
    -webkit-transform-origin: 0% 100%;
    -ms-transform-origin: 0% 100%;
    -moz-transform-origin: 0% 100%;
    -o-transform-origin: 0% 100%;
    transform-origin: 0% 100%;
    -webkit-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }
  100% {
    -webkit-transform-origin: 0% 100%;
    -ms-transform-origin: 0% 100%;
    -moz-transform-origin: 0% 100%;
    -o-transform-origin: 0% 100%;
    transform-origin: 0% 100%;
    -webkit-transform: scale(1, 0);
    -ms-transform: scale(1, 0);
    -moz-transform: scale(1, 0);
    -o-transform: scale(1, 0);
    transform: scale(1, 0);
  }
}
@-moz-keyframes elasticus {
  0% {
    -webkit-transform-origin: 0% 0%;
    -ms-transform-origin: 0% 0%;
    -moz-transform-origin: 0% 0%;
    -o-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    -webkit-transform: scale(1, 0);
    -ms-transform: scale(1, 0);
    -moz-transform: scale(1, 0);
    -o-transform: scale(1, 0);
    transform: scale(1, 0);
  }
  50% {
    -webkit-transform-origin: 0% 0%;
    -ms-transform-origin: 0% 0%;
    -moz-transform-origin: 0% 0%;
    -o-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    -webkit-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }
  50.1% {
    -webkit-transform-origin: 0% 100%;
    -ms-transform-origin: 0% 100%;
    -moz-transform-origin: 0% 100%;
    -o-transform-origin: 0% 100%;
    transform-origin: 0% 100%;
    -webkit-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }
  100% {
    -webkit-transform-origin: 0% 100%;
    -ms-transform-origin: 0% 100%;
    -moz-transform-origin: 0% 100%;
    -o-transform-origin: 0% 100%;
    transform-origin: 0% 100%;
    -webkit-transform: scale(1, 0);
    -ms-transform: scale(1, 0);
    -moz-transform: scale(1, 0);
    -o-transform: scale(1, 0);
    transform: scale(1, 0);
  }
}
@-o-keyframes elasticus {
  0% {
    -webkit-transform-origin: 0% 0%;
    -ms-transform-origin: 0% 0%;
    -moz-transform-origin: 0% 0%;
    -o-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    -webkit-transform: scale(1, 0);
    -ms-transform: scale(1, 0);
    -moz-transform: scale(1, 0);
    -o-transform: scale(1, 0);
    transform: scale(1, 0);
  }
  50% {
    -webkit-transform-origin: 0% 0%;
    -ms-transform-origin: 0% 0%;
    -moz-transform-origin: 0% 0%;
    -o-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    -webkit-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }
  50.1% {
    -webkit-transform-origin: 0% 100%;
    -ms-transform-origin: 0% 100%;
    -moz-transform-origin: 0% 100%;
    -o-transform-origin: 0% 100%;
    transform-origin: 0% 100%;
    -webkit-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }
  100% {
    -webkit-transform-origin: 0% 100%;
    -ms-transform-origin: 0% 100%;
    -moz-transform-origin: 0% 100%;
    -o-transform-origin: 0% 100%;
    transform-origin: 0% 100%;
    -webkit-transform: scale(1, 0);
    -ms-transform: scale(1, 0);
    -moz-transform: scale(1, 0);
    -o-transform: scale(1, 0);
    transform: scale(1, 0);
  }
}
@keyframes elasticus {
  0% {
    -webkit-transform-origin: 0% 0%;
    -ms-transform-origin: 0% 0%;
    -moz-transform-origin: 0% 0%;
    -o-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    -webkit-transform: scale(1, 0);
    -ms-transform: scale(1, 0);
    -moz-transform: scale(1, 0);
    -o-transform: scale(1, 0);
    transform: scale(1, 0);
  }
  50% {
    -webkit-transform-origin: 0% 0%;
    -ms-transform-origin: 0% 0%;
    -moz-transform-origin: 0% 0%;
    -o-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    -webkit-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }
  50.1% {
    -webkit-transform-origin: 0% 100%;
    -ms-transform-origin: 0% 100%;
    -moz-transform-origin: 0% 100%;
    -o-transform-origin: 0% 100%;
    transform-origin: 0% 100%;
    -webkit-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }
  100% {
    -webkit-transform-origin: 0% 100%;
    -ms-transform-origin: 0% 100%;
    -moz-transform-origin: 0% 100%;
    -o-transform-origin: 0% 100%;
    transform-origin: 0% 100%;
    -webkit-transform: scale(1, 0);
    -ms-transform: scale(1, 0);
    -moz-transform: scale(1, 0);
    -o-transform: scale(1, 0);
    transform: scale(1, 0);
  }
}

#paschka {
  display: block;
  color: white;
  font-family: helvetica neue, helvetica, arial;
  font-size: 32px;
  text-decoration: none;
  position: fixed;
  bottom: 0px;
  right: 0px;
  padding: 0px;
  transition: all 0.5s ease;
  background: #000;
  height: 40px;
  line-height: 30px;
  width: 40px;
  text-align: center;
  border-radius: 5%;
  bottom: 20px;
  right: 20px;
}
#paschka:hover {
  background: #232323;
  transition: all 0.1s ease;
  color: #f0f0f0;
}
</style>
