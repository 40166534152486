<template>
  <p :style="{ color: color, 'font-weight': weight, 'font-size': size }">
    {{ text }}
  </p>
</template>

<script>
export default {
  name: "SideBarText",
  props: {
    text: {
      type: String,
      required: true,
    },
    size: {
      type: String,
      default: "14px",
    },
    color: {
      type: String,
      default: "#AAAAAA",
    },
    weight: {
      type: String,
      default: "400",
    },
  },
};
</script>

<style scoped>
p {
  display: flex;
  align-items: center;
  justify-content: center;

  font-family: "Roboto", sans-serif;

  margin: 0px;
  padding: 0px;
}
</style>
