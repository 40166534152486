<template>
  <div class="boxBorder">
    <a :href="link">
      <img class="placeholder" :src="require('../../assets/' + image)" alt="" />
    </a>
    <!-- <a class="placeholder" href="https://randomuser.me/api/portraits/lego/6.jpg"></a> -->
  </div>
</template>

<script>
export default {
  name: "Icon",
  props: {
    image: {
      type: String,
      required: true,
      default: "",
    },
    link: String,
  },
};
</script>

<style scoped>
.boxBorder {
  width: 40px;
  height: 40px;
  padding: 0px;
  margin: 0px;

  display: flex;
  align-items: center;
  justify-content: center;
}
.placeholder {
  width: 35px;
  height: 35px;
  padding: 0px;
  margin: 0px;

  border: none;
}

img {
  filter: grayscale(100%);
  -webkit-filter: grayscale(100%);
  opacity: 0.6;
  -webkit-transition: 0.2s;
  -moz-transition: 0.2s;
  transition: 0.2s;
}
.placeholder:hover {
  filter: grayscale(0%);
  opacity: 1;
}
</style>
