<template>
  <div class="page">
    <div class="sideSection">
      <div class="sideBar" :class="toggleSection ? 'extend' : ''">
        <SideBar
          @sidebar-toggle="toggleSections"
          @sidebar-btn-clicked="btnClick"
          :topTextLogo="topTextLogo"
          :topSubText="topSubText"
          :bottomButtons="bottomButtons"
          :bottomIcons="bottomIcons"
          :highlightedBtn="highlightedSection"
        />
      </div>
    </div>
    <div class="sections" :class="toggleSection ? 'hideSection' : ''">
      <div
        class="introSection"
        id="intro"
        v-observe-visibility="{
          throttleOptions: {
            leading: 'visible',
          },
          callback: (isVisible, entry) => visibilityChanged(isVisible, `Home`),
        }"
      >
        <IntroSection :introText="introText" />
      </div>
      <div
        class="portfolioSection"
        id="portfolio"
        v-observe-visibility="{
          throttleOptions: {
            leading: 'hidden',
          },
          callback: (isVisible, entry) => visibilityChanged(isVisible, `Works`),
        }"
      >
        <PortfolioSection :cards="cards" />
      </div>
      <div
        class="aboutSection"
        id="about"
        v-observe-visibility="{
          throttleOptions: {
            leading: 'hidden',
          },
          callback: (isVisible, entry) => visibilityChanged(isVisible, `About`),
        }"
      >
        <AboutSection
          :groups="groups"
          :header="aboutSectionHeader"
          :about="aboutSectionAbout"
          :hobbies="aboutSectionHobbies"
        />
      </div>

      <div
        class="skillsSection"
        id="skills"
        v-observe-visibility="{
          throttleOptions: {
            leading: 'hidden',
          },
          callback: (isVisible, entry) =>
            visibilityChanged(isVisible, `Skills`),
        }"
      >
        <SkillsSection
          :skillSectionHeader="skillSection.header"
          :skillSectionSubHeader="skillSection.subHeader"
          :skillCards="skillSection.skillCards"
        />
      </div>
      <div
        class="contactSection"
        id="contact"
        v-observe-visibility="{
          throttleOptions: {
            leading: 'hidden',
          },
          callback: (isVisible, entry) =>
            visibilityChanged(isVisible, `Contact`),
        }"
      >
        <ContactSection
          :cards="aboutSection.cards"
          :header="aboutSection.header"
        />
      </div>
    </div>
  </div>
</template>

<script>
import SideBar from "../SideBar/SideBar.vue";
import IntroSection from "../IntroSection/IntroSection.vue";
import AboutSection from "../AboutSection/AboutSection.vue";
import PortfolioSection from "../PortfolioSection/PortfolioSection.vue";
import SkillsSection from "../SkillSection/SkillSection.vue";
import ContactSection from "../ContactSection/ContactSection.vue";

import tawkToLogo from "../../assets/icons/tawkToLogo.svg";
import sunnahVPNLogo from "../../assets/icons/sunnahVPNLogo.png";
import grafanaDashboard from "../../assets/icons/grafanaDashboard.png";
import epqGame from "../../assets/icons/epqGame.png";
import dockerLogo from "../../assets/icons/dockerIcon.svg";
import largeNodeJSLogo from "../../assets/icons/largeNodeJSIcon.svg";
import pythonLogo from "../../assets/icons/pythonIcon.svg";
import cSharpLogo from "../../assets/icons/cSharpIcon.svg";
import goLangIcon from "../../assets/icons/goLangIcon.svg";
import sqlIcon from "../../assets/icons/sqlIcon.svg";
import ansibleIcon from "../../assets/icons/ansibleIcon.svg";
import influxDBIcon from "../../assets/icons/influxDBIcon.svg";
import grafanaIcon from "../../assets/icons/grafanaIcon.svg";
import wireguardLogo from "../../assets/icons/wireguardIcon.svg";

export default {
  name: "HomePage",
  components: {
    SideBar,
    IntroSection,
    PortfolioSection,
    AboutSection,
    SkillsSection,
    ContactSection,
  },
  data() {
    return {
      toggleSection: false,
      highlightedSection: "home",
      //intro section
      introText: "Hi I'm Yusuf, a software developer",
      // navbar section
      topTextLogo: {
        textProps: {
          text: "Yusuf",
          size: "24px",
          color: "#FFFFFF",
          weight: "600",
        },
        image: "icons/logo.svg",
      },
      topSubText: [
        {
          text: "Yusuf Ibn Saifullah",
          size: "18px",
          color: "#AAAAAA",
          weight: "400",
        },
        {
          text: "Software Developer",
          size: "14px",
          color: "#AAAAAA",
          weight: "400",
        },
      ],
      bottomButtons: [
        {
          text: "Home",
          icon: "houseIcon.svg",
          link: "/#intro",
        },
        {
          text: "Works",
          icon: "laptopCodeIcon.svg",
          link: "/#portfolio",
        },
        {
          text: "About",
          icon: "userIcon.svg",
          link: "/#about",
        },
        {
          text: "Skills",
          icon: "checklistIcon.svg",
          link: "/#skills",
        },
        {
          text: "Contact",
          icon: "contactCardIcon.svg",
          link: "/#contact",
        },
      ],
      bottomIcons: [
        {
          image: "icons/gitlabIcon.svg",
          link: "https://gitlab.com/raspberry.tech",
        },
        {
          image: "icons/githubIcon.svg",
          link: "https://github.com/RaspberryTech01",
        },
        {
          image: "icons/linkedinIcon.svg",
          link: "https://linkedin.com/in/yusuf-ibn-saifullah-241a79190",
        },
      ],

      // portfolio section
      cards: [
        {
          header: "Wireguard Manager And API (Open Source)",
          image: wireguardLogo,
          textList: [
            "An open-source project which allows people to add and remove Wireguard clients via an API.",

            "Program features privacy focused functions such as removing client information (IP address) from \
              RAM after 5 minutes of inactivity.",

            "Application is built and exported as a docker container",
          ],
          textId: 1,
          icons: [
            {
              image: "icons/wireguardIcon.svg",
            },
            {
              image: "icons/goLangIcon.svg",
            },
            {
              image: "icons/dockerIcon.svg",
            },
          ],
        },
        {
          header: "Tawk.to Discord Bot",
          image: tawkToLogo,
          textList: [
            "A Discord bot made in NodeJS.",

            "Its purpose is to send a message to a specific channel\
                on Discord when a client opens a support ticket on the business’ tawk.to \
                support page.",

            "The application is secured and encrypted using https and by checking the incoming request \
                contains the preshared key from tawk.to",
          ],
          textId: 2,
          icons: [
            {
              image: "icons/nodeJSIcon.svg",
            },
            {
              image: "icons/expressjsIcon.svg",
            },
          ],
        },
        {
          header: "VPN Provider",
          image: sunnahVPNLogo,
          textList: [
            "React JS used to make simple components to be visualised in Storybook",

            "Wireguard VPN as the encrypted communication protocol",

            "NodeJS to manage users and data in a MariaDB database accessed via an expressJS API",

            "Golang for adding and removing client keys via secure API and storing basic information in sqlite database",
          ],
          textId: 3,
          icons: [
            {
              image: "icons/reactIcon.svg",
            },
            {
              image: "icons/storybookIcon.svg",
            },
            {
              image: "icons/wireguardIcon.svg",
            },
            {
              image: "icons/nodeJSIcon.svg",
            },
            {
              image: "icons/mariaDBIcon.svg",
            },
          ],
        },
        {
          header: "Server Monitoring And Alerting",
          image: grafanaDashboard,
          textList: [
            "Data from servers was collected using telegraf and stored in an influxDB instance",

            "Grafana was used on top of influxDB to visualise data with graphs and stats",

            "Alerting was setup and linked to PagerDuty to alert technicians if a server problem occurred",

            "Ansible was used to run commands on multiple servers at once",
          ],
          textId: 4,
          icons: [
            {
              image: "icons/influxDBIcon.svg",
            },
            {
              image: "icons/grafanaIcon.svg",
            },
            {
              image: "icons/pagerdutyIcon.svg",
            },
            {
              image: "icons/ansibleIcon.svg",
            },
          ],
        },
        {
          header: "EPQ Project - Mancala Board Game",
          image: epqGame,
          textList: [
            "For my computer science project at A-level I built a digital Mancala board game",

            "C# was used for the user interface and creation of the board game",

            "JavaScript was used to create a backend which stored login details for users, highscorers and \
              saved games (players could save the game and come back later)",
          ],
          textId: 5,
          icons: [
            {
              image: "icons/cSharpIcon.svg",
            },
            {
              image: "icons/nodeJSIcon.svg",
            },
            {
              image: "icons/mariaDBIcon.svg",
            },
            {
              image: "icons/expressjsIcon.svg",
            },
          ],
        },
        {
          header: "Building Docker containers",
          image: dockerLogo,
          textList: [
            "Docker was used to containerise NodeJS, Golang and Wireguard applications",

            "Docker-Compose was used to bring the containers up as well as setup port forwarding and setting of env variables",

            "Docker applications were automatically built when code was pushed to the repo",
          ],
          textId: 6,
          icons: [
            {
              image: "icons/dockerIcon.svg",
            },
            {
              image: "icons/gitlabIcon.svg",
            },
          ],
        },
      ],

      //about section
      groups: [
        {
          sectionTitle: "Work Experience",
          cards: [
            {
              header: "Tech Studio",
              jobTitle: "Course Assistant",
              text: "I have learnt how to teach children the basics of coding with Scratch and Python, robotics, stop motion animation and video editing.\
                Teaching younger children takes patience and helps build communication skills.",
              address:
                "The Enterprise Centre, 61 Ditton Walk, Cambridge, CB1 3NL",
              longevity: "March 2019 - Present",
            },
            {
              header: "SunnahVPN",
              jobTitle: "CEO",
              text: "I lead a team of developers in the creation of software to communicate with servers running Wireguard VPN. \
                There was a lot learnt during the creation process - things varying from business, financial, software development as well as DevOps.",
              address: "",
              longevity: "October 2020 - Present",
            },
            {
              header: "GE Renewable Energy",
              jobTitle: "Work Experience Placement",
              text: "I worked with GE university student interns and learned about the elastic stack and Kubernetes open-source platform used by GE.\
                I came away with a good insight into software engineering in a real business.",
              address:
                "Sovereign House, Vision Park, Histon, Cambridge CB24 9ZR",
              longevity: "23 October 2019 - 25 October 2019 ",
            },
            {
              header: "Al-Amin Stores",
              jobTitle: "Shop Assistant",
              text: "My experience made me more confident when talking to people and more efficient- \
                whether stacking and tidying shelves, baking pastries or helping customers find international food items.",
              address: "100A-102A Mill Road, Cambridge, CB1 2BD",
              longevity: "August 2015- August 2019",
            },
          ],
        },
        {
          sectionTitle: "Extra Curricular Activities",
          cards: [
            {
              header: "Robotics group at Sixth Form",
              jobTitle: "Robotics Assembler",
              text: "I worked on the robot build team for a RoboCon competition at the college. \
                I was one of the people who worked on assembling the robot and taking measurements to create 3D printed parts.",
              address: "Hills Road Sixth Form College",
              longevity: "September 2019 - 2020",
            },
            {
              header: "CyberDiscovery",
              jobTitle: "Player",
              text: "Yearly online cyber security competition that hones cyber security skills through problem solving, cryptography \
                and programming challenges.",
              address: "",
              longevity: "October 2017 - 2020",
            },
            {
              header: "CyberFirst Advanced Course",
              jobTitle: "Attendee",
              text: "I learned about: implementing digital forensics, understanding encryption technologies, using open source \
                intelligence techniques and penetration testing.",
              address: "",
              longevity: "5-9 August 2019",
            },
            {
              header: "CyberFirst Futures Course",
              jobTitle: "Attendee",
              text: "I learned about: cyber attacks, networks; how to protect a network \
                from attack and how to make resources available and secure to others.",
              address: "",
              longevity: "5-9 August 2019",
            },
          ],
        },
      ],
      aboutSectionHeader: "About Me",
      aboutSectionAbout:
        "I am a friendly, reliable and hard-working student who enjoys hands-on projects, coding, computer science and technology. \
        In the future I would like to study computer science with a specialization in software engineering.",
      aboutSectionHobbies:
        "My hobbies include programming, watching technology videos on Youtube, learning new skills such as understanding how docker \
        and containers work, gaming and working out.",

      //skill section
      skillSection: {
        header: "Skills",
        subHeader:
          "Below you can view my skills based on level and experience.",
        skillCards: [
          {
            skillName: "NodeJS",
            skillLevel: "Intermediate",
            skillUseCase: [
              "Creating secure APIs using authentication headers and parsing of \
                JSON and x-www-form-urlencoded data",
              "Connecting to a database to create tables and CRUD data",
              "Using SMTP to send emails to customers",
              "Libraries such as Bcrypt and JWT to encrypt data and generate user \
                authentication tokens",
              "Creation of a webhook Discord bot",
            ],
            skillImage: largeNodeJSLogo,
          },
          {
            skillName: "Golang",
            skillLevel: "Beginner/Intermediate",
            skillUseCase: [
              "Made a VPN server controller - added and removed Wireguard keys and reapplied keys after inactivity period",
              "Connected to a Sqlite DB using the Gorm library",
              "Used in automatically creating subnets and bringing interfaces up when program was ran (netlink)",
              "Created a secure API which only allows requests from authenticated sources",
            ],
            skillImage: goLangIcon,
          },
          {
            skillName: "C#",
            skillLevel: "Intermediate",
            skillUseCase: [
              "Created a digital board game interface in C#. Game connected to the backend by making API requests",
              "Used with windows forms to create a GUI",
              "Previously connected to a database to run SQL commands",
            ],
            skillImage: cSharpLogo,
          },
          {
            skillName: "Docker",
            skillLevel: "Intermediate",
            skillUseCase: [
              "Built containers to serve applications",
              "Used docker-compose to quickly serve built containers",
              "Auto build was setup so that runners automatically build \
                an image with fresh code",
            ],
            skillImage: dockerLogo,
          },
          {
            skillName: "Python",
            skillLevel: "Beginner",
            skillUseCase: [
              "Studied during GCSE computer science",
              "Created a simple discord bot",
              "Used in simple projects such as a calculator application",
            ],
            skillImage: pythonLogo,
          },
          {
            skillName: "SQL",
            skillLevel: "Beginner/Intermediate",
            skillUseCase: [
              "Studied during A-level computer science",
              "Used in my computer science project for A-level to CRUD data",
            ],
            skillImage: sqlIcon,
          },
          {
            skillName: "Ansible",
            skillLevel: "Beginner",
            skillUseCase: [
              "Used to manage a few servers",
              "Small stories were created to quickly run commands such as updating the servers",
            ],
            skillImage: ansibleIcon,
          },
          {
            skillName: "InfluxDB",
            skillLevel: "Beginner",
            skillUseCase: [
              "Stored server information",
              "Setup alerting to external services such as Pagerduty when an issue on a server occurred",
            ],
            skillImage: influxDBIcon,
          },
          {
            skillName: "Grafana",
            skillLevel: "Beginner",
            skillUseCase: [
              "Created a dashboard to visualise data from InfluxDB",
              "Setup variables to be able to change server information easily",
            ],
            skillImage: grafanaIcon,
          },
        ],
      },

      //about section
      aboutSection: {
        cards: [
          {
            text: "yusuf.ibnsaifullah@gmail.com",
            btnText: "Email",
            link: "mailto:yusuf.ibnsaifullah@gmail.com",
            image: "icons/contactCardIcon.svg",
          },
          {
            text: "Yusuf Ibn Saifullah",
            btnText: "LinkedIn",
            link: "https://www.linkedin.com/in/yusuf-ibn-saifullah-241a79190/",
            image: "icons/linkedinIcon.svg",
          },
          {
            text: "raspberry.tech",
            btnText: "Gitlab",
            link: "https://gitlab.com/raspberry.tech",
            image: "icons/gitlabIcon.svg",
          },
          {
            text: "RaspberryTech01",
            btnText: "Github",
            link: "https://github.com/RaspberryTech01",
            image: "icons/githubIcon.svg",
          },
        ],
        header: "Contact Me",
      },
    };
  },
  methods: {
    toggleSections(toggled) {
      this.toggleSection = toggled;
    },
    btnClick(stuff) {
      this.toggleSection = !stuff;
    },
    visibilityChanged(isVisible, idPassed) {
      this.isVisible = isVisible;
      if (isVisible) {
        this.highlightedSection = idPassed;
        console.log(idPassed);
      }
    },
  },
};
</script>

<style scoped>
html {
  scroll-behavior: smooth;
}
.page {
  display: flex;
  width: 100%;
}
@media screen and (min-width: 601px) {
  .sections {
    width: 100%;
  }
  .sideSection {
    /* width: 188px; */
    position: relative;

    background: #181818;
  }
  .sideBar {
    width: inherit;
    position: sticky;
    position: -webkit-sticky;
    position: -moz-sticky;
    position: -o-sticky;
    position: -ms-sticky;
    top: 0;
  }
}
@media screen and (max-width: 600px), (max-height: 650px) {
  .sections {
    width: 100%;
    overflow: auto;
  }
  .sideSection {
    position: relative;
    background: #181818;
  }
  .sideBar {
    width: inherit;
    position: sticky;
    position: -webkit-sticky;
    position: -moz-sticky;
    position: -o-sticky;
    position: -ms-sticky;
    top: 0;
    transition: width 2s;
    -moz-transition: left 1s ease 1s, width 0s ease-out 1s,
      border-width 0s ease-out 1s;
    -webkit-transition: left 1s ease 1s, width 0s ease-out 1s,
      border-width 0s ease-out 1s;
  }
  /* .hideSection{
  }  */
  .extend {
    overflow: hidden;
    transition: width 2s;
    width: 100vw;
    -moz-transition: width 1s ease-out 1s;
    -webkit-transition: width 1s ease-out 1s;
  }
}
</style>
