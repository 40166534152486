<template>
  <div class="home">
    <HomePage />
  </div>
</template>

<script>
// @ is an alias to /src
import HomePage from "../components/HomePage/HomePage.vue";
export default {
  name: "Home",
  components: {
    HomePage,
  },
};
</script>

<style scoped>
body,
.home {
  margin: 0px;
}
</style>
