<template>
  <div class="contactSection">
    <div class="innerContactSection">
      <div class="headerDiv">
        <p class="header">{{ header }}</p>
      </div>
      <div>
        <ContactCardGroup :cards="cards" />
      </div>
    </div>
  </div>
</template>

<script>
import ContactCardGroup from "../ContactCardGroup/ContactCardGroup.vue";

export default {
  name: "ContactSection",
  props: {
    cards: {
      Type: Array,
    },
    header: {
      Type: String,
    },
  },
  components: {
    ContactCardGroup,
  },
};
</script>

<style scoped>
.header {
  font-family: "Roboto", sans-serif;
  color: #ffffff;
  margin: 0px;
  text-align: left;
}
.headerDiv {
  padding-bottom: 5px;
}
.contactSection {
  background-color: #252627;
}

@media screen and (min-width: 601px) {
  .innerContactSection {
    padding: 20px 50px 20px 50px;
  }
  .header {
    font-size: 48px;
  }
}

@media screen and (max-width: 600px), (max-height: 750px) {
  .innerContactSection {
    padding: 20px 20px 20px 20px;
  }
  .header {
    font-size: 32px;
  }
}
</style>
