<template>
  <div>
    <div class="bgBarOverall">
      <div
        class="bgBarTop"
        :class="sideBarToggle ? 'purple' : ''"
        @click="toggleSideBar()"
      >
        <div class="bgBar" :class="sideBarToggle ? 'white' : 'black'">
          <div class="barCheck">
            <div
              class="line line1"
              :class="sideBarToggle ? 'rotateLineClock black' : 'white'"
            ></div>
            <div
              class="line line2"
              :class="sideBarToggle ? 'hide black' : 'white'"
            ></div>
            <div
              class="line line3"
              :class="sideBarToggle ? 'rotateLineAnti black' : 'white'"
            ></div>
          </div>
        </div>
      </div>
    </div>
    <div class="bar" :class="sideBarToggle ? 'toggle' : 'unToggle'">
      <SideBarTop :textLogo="topTextLogo" :subText="topSubText" />
      <SideBarBottom
        @sidebar-btn-clicked="emitUpward"
        :buttons="bottomButtons"
        :icons="bottomIcons"
        :highlightedBtn="highlightedBtn"
      />
    </div>
  </div>
</template>

<script>
import SideBarTop from "../SideBarTop/SideBarTop.vue";
import SideBarBottom from "../SideBarBottom/SideBarBottom.vue";

export default {
  name: "SideBar",
  props: {
    topTextLogo: {
      type: Object,
      required: true,
    },
    topSubText: {
      type: Array,
      required: true,
    },
    bottomButtons: {
      type: Array,
      required: true,
    },
    bottomIcons: {
      type: Array,
      required: true,
    },
    highlightedBtn: {
      type: String,
    },
  },
  components: {
    SideBarTop,
    SideBarBottom,
  },
  data() {
    return {
      sideBarToggle: false,
    };
  },
  methods: {
    toggleSideBar() {
      this.sideBarToggle = !this.sideBarToggle;
      this.$emit("sidebar-toggle", this.sideBarToggle);
    },
    sideBarOff() {
      this.sideBarToggle = false;
    },
    emitUpward(stuff) {
      this.$emit("sidebar-btn-clicked", stuff);
      this.sideBarOff();
    },
  },
};
</script>

<style scoped>
@media screen and (min-width: 601px) {
  .bar {
    display: block;
  }
  .barCheck,
  .bgBar,
  .bgBarTop {
    display: none;
  }
}
@media screen and (max-width: 600px), (max-height: 650px) {
  .bar {
    display: none;
  }
  .bgBarOverall {
    padding: 10px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    position: fixed;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 60px;
    transition: 0.2s;
  }
  .bgBarOverall:hover {
    -webkit-transform: scale(1.3);
    -ms-transform: scale(1.3);
    transform: scale(1.3);
    transition: 0.2s;
  }
  .bgBarTop {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    border: 2px solid #ffffff;
    border-radius: 50px;
  }
  .bgBar {
    background-color: black;
    width: 40px;
    height: 40px;
    border-radius: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .barCheck {
    display: block;
    width: 25px;
    height: 20px;
  }
  .toggle {
    opacity: 1;
    display: block;
  }
  .unToggle {
    display: none;
    transition: opacity 1s ease-out;
    opacity: 0;
  }
  .line {
    height: 3px;
    margin-bottom: 5px;
  }
  .line1 {
    width: 25px;
  }
  .line2 {
    width: 15px;
  }
  .line3 {
    width: 5px;
  }
  .rotateLineClock {
    width: 30px;
    transform: translateY(8px) translateX(-3px) rotate(45deg);
    background-color: #802626;
  }
  .rotateLineAnti {
    width: 30px;
    transform: translateY(-8px) translateX(-3px) rotate(-45deg);
    background-color: #802626;
  }
  .white {
    transition: 0.2s;
    background-color: #ffffff;
  }
  .black {
    transition: 0.2s;
    background-color: black !important;
  }
  .hide {
    transition: 0.2s;
    opacity: 0;
  }
}
</style>
