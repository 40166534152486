<template>
  <div class="cardGroup">
    <div>
      <p class="sectionTitle">{{ sectionTitle }}</p>
    </div>
    <div class="cards">
      <div class="singleCard" :key="card.header" v-for="card in cards">
        <AboutCard
          :header="card.header"
          :jobTitle="card.jobTitle"
          :text="card.text"
          :address="card.address"
          :longevity="card.longevity"
        />
      </div>
    </div>
  </div>
</template>

<script>
import AboutCard from "../AboutCard/AboutCard.vue";

export default {
  name: "AboutCardGroup",
  props: {
    cards: {
      Type: Array,
      required: true,
    },
    sectionTitle: String,
  },
  components: {
    AboutCard,
  },
};
</script>

<style scoped>
.cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.singleCard {
  padding: 10px;
}
.sectionTitle {
  margin: 0px;
  padding: 20px 0px 10px 10px;
  font-size: 24px;
  font-weight: 500;
  font-family: "Roboto", sans-serif;
  color: #ffffff;
  display: flex;
  justify-content: center;
}
</style>
