<template>
  <!-- <div id="nav">
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>
  </div> -->
  <router-view />
</template>

<style>
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

#app {
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
body {
  margin: 0px;
}
body::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

body::-webkit-scrollbar-track {
  background-color: black;
}
body::-webkit-scrollbar-thumb {
  background: #48bcff;
  border-radius: 25px;
}
body::-webkit-scrollbar-thumb:hover {
  background: #3488b9;
}
body::-webkit-scrollbar-thumb:active {
  background: #ef37f9;
}
</style>
