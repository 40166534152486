<template>
  <div class="section">
    <div class="innerSection">
      <p class="header">Portfolio</p>
      <PortfolioCardGroup :cards="cards" />
    </div>
  </div>
</template>

<script>
import PortfolioCardGroup from "../PortfolioCardGroup/PortfolioCardGroup.vue";

export default {
  props: {
    cards: {
      Type: Array,
      required: true,
    },
  },
  components: {
    PortfolioCardGroup,
  },
};
</script>

<style scoped>
.section {
  background-color: #252627;
}
.header {
  font-weight: 500;
  font-family: "Roboto", sans-serif;
  margin: 0px;
  color: #ffffff;
  text-align: left;
}

/*Tablet portrait*/
@media screen and (min-width: 601px) {
  .innerSection {
    padding: 25px 40px 25px 40px;
  }
  .header {
    font-size: 32px;
    padding-bottom: 25px;
  }
}

@media screen and (min-width: 992px) {
  .innerSection {
    padding: 50px 80px 50px 80px;
  }
  .header {
    font-size: 48px;
    padding-bottom: 50px;
  }
}

/* Small and large phones*/
@media screen and (max-width: 600px), (max-height: 650px) {
  .innerSection {
    padding: 25px 20px 25px 20px;
  }
  .header {
    font-size: 32px;
    padding-bottom: 25px;
  }
}
</style>
