<template>
  <div class="skillsCardGroup">
    <div
      class="singleSkillsCard"
      :key="card.skillName"
      v-for="card in skillCards"
    >
      <SkillsCard
        :skillName="card.skillName"
        :skillLevel="card.skillLevel"
        :skillUseCase="card.skillUseCase"
        :skillImage="card.skillImage"
      />
    </div>
  </div>
</template>

<script>
import SkillsCard from "../SkillsCard/SkillsCard.vue";

export default {
  name: "SkillsCardGroup",
  props: {
    skillCards: {
      Type: Array,
    },
  },
  components: {
    SkillsCard,
  },
};
</script>

<style scoped>
.skillsCardGroup {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.singleSkillsCard {
  padding: 10px;
}
</style>
