<template>
  <div class="sideBar">
    <SideBarBtnGroup
      @sidebar-btn-clicked="emitUpward"
      class="btnGroup"
      :buttons="buttons"
      :highlightedBtn="highlightedBtn"
    />
    <IconGroup class="iconGroup" :icons="icons" />
  </div>
</template>

<script>
import SideBarBtnGroup from "../SideBarBtnGroup/SideBarBtnGroup.vue";
import IconGroup from "../IconGroup/IconGroup.vue";

export default {
  name: "SideBarBottom",
  props: {
    buttons: {
      type: Array,
      required: true,
    },
    icons: {
      type: Array,
      required: true,
    },
    highlightedBtn: {
      type: String,
    },
  },
  components: {
    SideBarBtnGroup,
    IconGroup,
  },
  methods: {
    emitUpward(stuff) {
      this.$emit("sidebar-btn-clicked", stuff);
    },
  },
};
</script>

<style scoped>
@media screen and (min-width: 601px) {
  .sideBar {
    width: 188px;
    padding-top: 40px;
    background: #181818;
    height: 100%;
  }
  .btnGroup {
    padding-bottom: 40px;
  }
}

@media screen and (max-width: 600px), (max-height: 650px) {
  .sideBar {
    width: 100%;
    padding-top: 12px;
    background: #181818;
    height: 100%;
  }
  .btnGroup {
    padding-bottom: 20px;
  }
  .iconGroup {
    width: 100%;
  }
}
</style>
