<template>
  <div class="box">
    <div class="items imageBox">
      <img class="placeholder" :src="require('../../assets/' + image)" alt="" />
    </div>
    <div class="items textBox">
      <p>{{ text }}</p>
    </div>
    <div class="items btnBox">
      <a :href="link" target="_blank" rel="noopener noreferrer">
        <button>{{ btnText }}</button>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: "ContactCard",
  props: {
    text: {
      Type: String,
    },
    btnText: {
      Type: String,
    },
    link: {
      Type: String,
    },
    image: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped>
p,
button {
  font-family: "Roboto", sans-serif;
  margin: 0px;
  font-weight: 500;
}
p {
  color: #ffffff;
}

button {
  cursor: pointer;
  background: #ffffff;
  border-radius: 10px;
  border: 0px;
  font-weight: 500;
  color: #181818;
}
button:hover {
  transition: 0.2s;
  color: #ffffff;
  background: #000000;
}
.items {
  width: 100%;
  display: flex;
  align-content: center;
  justify-content: center;
}
.box {
  background-color: #181818;
  border-radius: 10px;
  box-shadow: 0px 4px 15px 0px #00000026;
}
/* .imageBox {
  display: flex;
  justify-items: center;
} */
@media screen and (min-width: 601px) {
  .box {
    width: 350px;
  }
  .placeholder {
    width: 40px;
    height: 40px;
    padding-top: 15px;
  }
  .textBox {
    height: 35px;
  }
  .btnBox {
    padding-top: 10px;
    padding-bottom: 15px;
  }
  p {
    font-size: 22px;
    padding-top: 10px;
  }
  button {
    font-size: 20px;
    width: 300px;
    height: 40px;
  }
}
@media screen and (max-width: 600px), (max-height: 750px) {
  .box {
    width: 300px;
  }
  .placeholder {
    padding-top: 10px;
    width: 30px;
    height: 30px;
  }
  .textBox {
    height: 35px;
  }
  .btnBox {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  p {
    font-size: 18px;
    padding-top: 10px;
  }
  button {
    font-size: 16px;
    width: 250px;
    height: 30px;
  }
}
</style>
