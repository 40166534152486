<template>
  <div class="contactCards">
    <div class="singleContactCard" :key="card.text" v-for="card in cards">
      <ContactCard
        :text="card.text"
        :btnText="card.btnText"
        :link="card.link"
        :image="card.image"
      />
    </div>
  </div>
</template>

<script>
import ContactCard from "../ContactCard/ContactCard.vue";
export default {
  name: "ContactCardGroup",
  props: {
    cards: Array,
  },
  components: {
    ContactCard,
  },
};
</script>

<style scoped>
.contactCards {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
.singleContactCard {
  padding: 10px;
}
</style>
