<template>
  <div>
    <router-link :to="link" class="router" @click="clickedOn()">
      <img
        :class="[highlight ? 'highlighted' : '']"
        :src="require('../../assets/icons/' + icon)"
        alt=""
      />
      <button :class="[highlight ? 'highlighted' : '', 'sideBarBtn']">
        {{ text }}
      </button>
    </router-link>
  </div>
</template>

<script>
export default {
  name: "SideBarBtn",
  props: {
    text: {
      type: String,
      required: true,
    },
    highlight: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      required: true,
    },
    link: {
      type: String,
      required: true,
    },
  },
  data() {},
  methods: {
    clickedOn() {
      this.$emit("sidebar-btn-clicked", true);
    },
  },
};
</script>

<style scoped>
div {
  display: flex;
  align-items: center;
  background-color: #181818;
}
.router {
  display: flex;
  text-decoration: none;
}
img {
  border: none;
  height: 30px;
  width: 30px;
  cursor: pointer;
  filter: grayscale(100%);
  -webkit-filter: grayscale(100%);
  opacity: 0.6;
}
.sideBarBtn {
  margin: 0px;
  border: none;
  padding-left: 10px;

  background-color: #ffffff00;
  color: #aaaaaa;
  font-size: 18px;
  font-family: "Roboto", sans-serif;
  cursor: pointer;
}
.highlighted {
  color: #ffffff;
  font-weight: 700;
  filter: grayscale(0%);
  -webkit-filter: grayscale(0%);
  opacity: 1;
}
@media screen and (min-width: 601px) {
  div {
    width: 188px;
    height: 60px;
  }
  img {
    padding-left: 40px;
  }
}
@media screen and (max-width: 600px), (max-height: 750px) {
  div {
    width: 100%;
    height: 40px;
    justify-content: center;
  }
  img {
    padding: 0px;
  }
}
</style>
