<template>
  <div class="placeholder">
    <div :key="icon.image" v-for="icon in icons">
      <Icon class="icon" :image="icon.image" :link="icon.link" />
    </div>
  </div>
</template>

<script>
import Icon from "../Icon/Icon.vue";

export default {
  name: "IconGroup",
  props: {
    icons: {
      Type: Array,
      required: true,
    },
  },
  components: {
    Icon,
  },
};
</script>

<style scoped>
.placeholder {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
.icon {
  text-align: center;
  float: left;
  margin: 0px;
  padding: 0px 5px 10px 5px;
  border: none;
}
</style>
