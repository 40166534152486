<template>
  <div class="skillsSection">
    <div class="innerSkillsSection">
      <p class="skillsSectionHeader">{{ skillSectionHeader }}</p>
      <p class="skillsSectionSubHeader">{{ skillSectionSubHeader }}</p>
      <SkillsCardGroup :skillCards="skillCards" />
    </div>
  </div>
</template>

<script>
import SkillsCardGroup from "../SkillsCardGroup/SkillsCardGroup.vue";

export default {
  name: "SkillSection",
  props: {
    skillSectionHeader: String,
    skillSectionSubHeader: String,
    skillCards: {
      Type: Array,
    },
  },
  components: {
    SkillsCardGroup,
  },
};
</script>

<style scoped>
* {
  font-family: "Roboto", sans-serif;
}
p {
  margin: 0px;
  text-align: left;
}
.skillsSection {
  background-color: #252627;
}
.skillsSectionHeader {
  font-weight: 500;
  color: #ffffff;
}
.skillsSectionSubHeader {
  font-weight: 400;
  color: #ffffff;
}

@media screen and (min-width: 601px) {
  .innerSkillsSection {
    padding: 20px 40px 20px 40px;
  }
  .skillsSectionHeader {
    font-size: 48px;
  }
  .skillsSectionSubHeader {
    padding-top: 15px;
    padding-bottom: 15px;
    font-size: 18px;
  }
}

@media screen and (min-width: 992px) {
  .innerSkillsSection {
    padding: 20px 80px 20px 80px;
  }
  .skillsSectionHeader {
    font-size: 48px;
  }
  .skillsSectionSubHeader {
    padding-top: 20px;
    padding-bottom: 20px;
    font-size: 18px;
  }
}

@media screen and (max-width: 600px), (max-height: 750px) {
  .innerSkillsSection {
    padding: 15px 20px 15px 20px;
  }
  .skillsSectionHeader {
    font-size: 36px;
  }
  .skillsSectionSubHeader {
    padding-top: 20px;
    padding-bottom: 20px;
    font-size: 16px;
  }
}
</style>
