<template>
  <div>
    <img :src="require('../../assets/' + image)" alt="" />
    <Text
      :text="textProps.text"
      :size="textProps.size"
      :color="textProps.color"
      :weight="textProps.weight"
    />
  </div>
</template>

<script>
import Text from "../SideBarText/SideBarText.vue";
export default {
  name: "TextLogo",
  props: {
    textProps: {
      type: Object,
    },
    image: {
      type: String,
    },
  },
  components: {
    Text,
  },
};
</script>

<style scoped>
div {
  width: 188px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin: 0px;
  padding: 0px;
}
img {
  height: 40px;
  width: 40px;
  text-align: center;
  float: left;
  padding-right: 5px;
}
Text {
  font-size: 24px;
  font-weight: 500;
  font-family: "Roboto", sans-serif;
  text-align: center;
  float: left;
  padding-left: 5px;
}
</style>
