import { default } from '../AboutCard/AboutCard.vue';
<template>
  <div class="skillCard" :style="backgroundStyles(skillImage)" onclick>
    <div class="innerSkillCard">
      <div class="skillTopText">
        <p class="skillName">{{ skillName }}</p>
        <p class="skillLevel">{{ skillLevel }}</p>
      </div>
      <div class="skillBottomText">
        <ul>
          <li
            class="singleSkillText"
            :key="skillText"
            v-for="skillText in skillUseCase"
          >
            {{ skillText }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SkillsCard",
  props: {
    skillName: {
      Type: String,
      required: true,
    },
    skillLevel: {
      Type: String,
    },
    skillUseCase: {
      Type: Array,
    },
    skillImage: {
      Type: String,
    },
  },
  methods: {
    backgroundStyles(image) {
      return {
        // any other styles you might need to add on as an example
        "background-image": `url("${image}")`,
        "background-position": "center",
        "background-repeat": "no-repeat",
        "background-size": "auto 75%",
      };
    },
  },
};
</script>

<style scoped>
* {
  font-family: "Roboto", sans-serif;
}
p {
  margin: 0px;
  color: #ffffff;
  visibility: hidden;
  text-align: left;
}
ul {
  margin: 0px;
  padding-top: 10px;
}
li {
  font-weight: 400;
  margin: 0px;
  padding-bottom: 3px;
  color: #ffffff;
  visibility: hidden;
  text-align: left;
}
.skillCard {
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 4px 15px 0px #00000026;
  transition: 0.3s;
}
.innerSkillCard {
  transition: 0.2s;
  border-radius: 10px;
}
.skillTopText {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.skillTopText .skillLevel {
  text-align: right;
}
.skillTopText .skillName {
  font-weight: 500;
}
.skillTopText .skillLevel {
  font-weight: 500;
}
.skillCard:hover,
.skillCard:hover .innerSkillCard {
  background-color: #181818;
  background-image: none;
}
.skillCard:hover p,
.skillCard:hover li {
  visibility: visible;
}

@media screen and (min-width: 601px) {
  .innerSkillCard {
    padding: 20px;
  }
  .skillCard {
    width: 500px;
  }
  .skillTopText .skillName {
    font-size: 24px;
  }
  .skillTopText .skillLevel {
    font-size: 18px;
  }
  li {
    font-size: 16px;
  }
}

@media screen and (max-width: 600px), (max-height: 750px) {
  .innerSkillCard {
    padding: 10px;
  }
  .skillCard {
    width: 300px;
  }
  .skillTopText .skillName {
    font-size: 20px;
  }
  .skillTopText .skillLevel {
    font-size: 16px;
  }
  li {
    font-size: 14px;
  }
}
</style>
