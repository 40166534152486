<template>
  <!-- https://dev.webonomic.nl/fixing-the-iphone-css-hover-problem-on-ios -->
  <div class="card" :style="backgroundStyles(image)" onclick>
    <div class="innerCard">
      <p class="header">{{ header }}</p>
      <!-- <img class="image" :src="require('../../assets/icons/' + image)" alt=""> -->
      <!-- <p class="text">{{text}}</p> -->
      <div class="text">
        <li :key="text.id" v-for="text in textList" class="eachText">
          {{ text }}
        </li>
      </div>

      <IconGroup class="iconGroup" :icons="icons" />
    </div>
  </div>
</template>

<script>
import IconGroup from "../IconGroup/IconGroup.vue";

export default {
  name: "PortfolioCard",
  props: {
    header: {
      type: String,
      required: true,
    },
    image: {
      type: String,
    },
    textList: {
      type: Array,
    },
    textId: Number,
    icons: {
      type: Array,
    },
  },
  components: {
    IconGroup,
  },
  methods: {
    backgroundStyles(image) {
      return {
        // any other styles you might need to add on as an example
        "background-image": `url("${image}")`,
        "background-position": "center",
        "background-repeat": "no-repeat",
        "background-size": "auto 55%",
      };
    },
  },
};
</script>

<style scoped>
.card {
  background-color: #181818;
  border-radius: 5px;
  box-shadow: 0px 4px 15px 0px #00000026;
}

p,
li {
  color: #ffffff;
  font-family: "Roboto", sans-serif;
  text-align: left;
}
li {
  padding-bottom: 3px;
}
.header {
  font-weight: 500;
  margin: 0px;
}

.iconGroup,
.eachText {
  opacity: 0;
  display: none;
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  transition: 0.5s;
}
.innerCard {
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  transition: 0.3s;
}
.card:hover .iconGroup,
.card:hover .eachText,
.card:hover,
.iconGroup:hover .iconGroup,
.eachText:hover .eachText,
.innerCard:hover .innerCard {
  background-image: none;
  opacity: 1;
  display: flex;
}
.card:hover .innerCard {
  background-color: #181818;
  border-radius: 5px;
}
@media screen and (min-width: 601px) {
  .card {
    width: 350px;
    height: 350px;
  }
  .header {
    font-size: 24px;
    padding: 0px 0px 10px 0px;
  }
  .text {
    font-size: 16px;
    margin: 0px;
    padding: 0px 0px 10px 0px;
  }
  .innerCard {
    padding: 20px 20px 20px 20px;
  }
}

@media screen and (max-width: 600px), (max-height: 750px) {
  .card {
    width: 300px;
    height: 300px;
  }
  .header {
    font-size: 20px;
    padding: 0px 0px 15px 0px;
  }
  .text {
    font-size: 14px;
    margin: 0px;
    padding-bottom: 15px;
  }
  .innerCard {
    padding: 10px 10px 10px 10px;
  }
}
</style>
