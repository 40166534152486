<template>
  <div class="btnGroup">
    <div :key="button.text" v-for="button in buttons" class="btn">
      <Button
        @sidebar-btn-clicked="emitUpward"
        class="button"
        :text="button.text"
        :highlight="button.text == highlightedBtn ? true : false"
        :icon="button.icon"
        :link="button.link"
      />
    </div>
  </div>
</template>

<script>
import Button from "../SideBarBtn/SideBarBtn.vue";

export default {
  name: "SideBarBtnGroup",
  props: {
    buttons: {
      type: Array,
      required: true,
    },
    highlightedBtn: {
      type: String,
    },
  },
  components: {
    Button,
  },
  methods: {
    emitUpward(stuff) {
      this.$emit("sidebar-btn-clicked", stuff);
      console.log(this.highlightedBtn);
    },
  },
};
</script>

<style scoped>
.btnGroup {
  padding: 0px;
  margin: 0px;
}
.btn {
  display: flex;
  justify-content: center;
}
</style>
