<template>
  <div class="total">
    <div class="innerTotal">
      <div class="textSection">
        <p class="headerText">{{ header }}</p>
        <p class="aboutMeText">{{ about }}</p>
        <p class="hobbiesText">{{ hobbies }}</p>
      </div>
      <div class="totalGroup">
        <div
          class="cardsGroup"
          :key="group.sectionTitle"
          v-for="group in groups"
        >
          <AboutCardGroup
            :cards="group.cards"
            :sectionTitle="group.sectionTitle"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AboutCardGroup from "../AboutCardGroup/AboutCardGroup.vue";

export default {
  name: "AboutSection",
  props: {
    groups: {
      Type: Array,
      required: true,
    },
    header: {
      Type: String,
      required: true,
    },
    about: {
      Type: String,
      required: true,
    },
    hobbies: {
      Type: String,
      required: true,
    },
  },
  components: {
    AboutCardGroup,
  },
};
</script>

<style scoped>
p {
  font-family: "Roboto", sans-serif;
  margin: 0px;
}
.headerText {
  color: #ffffff;
  font-weight: 500;
  padding-bottom: 20px;
}
.aboutMeText,
.hobbiesText {
  font-weight: 400;
  color: #ffffff;
}
.totalGroup {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.total {
  background-color: #252627;
}
.textSection {
  text-align: left;
}
@media screen and (min-width: 601px) {
  .innerTotal {
    padding: 20px 50px 20px 50px;
  }
  .textSection {
    width: 75%;
  }
  .headerText {
    font-size: 48px;
  }
  .aboutMeText,
  .hobbiesText {
    font-size: 16px;
    padding-bottom: 10px;
  }
}

@media screen and (min-width: 992px) {
  .innerTotal {
    padding: 20px 80px 20px 80px;
  }
  .textSection {
    width: 50%;
  }
  .headerText {
    font-size: 48px;
  }
  .aboutMeText,
  .hobbiesText {
    font-size: 16px;
    padding-bottom: 10px;
  }
}

@media screen and (max-width: 600px), (max-height: 750px) {
  .innerTotal {
    padding: 20px 20px 20px 20px;
  }
  .textSection {
    width: 100%;
  }
  .headerText {
    font-size: 32px;
  }
  .aboutMeText {
    font-size: 14px;
    padding-bottom: 10px;
  }
  .hobbiesText {
    font-size: 14px;
    padding-bottom: 0px;
  }
}
</style>
