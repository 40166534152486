import { default } from '../HomePage/HomePage.vue';
<template>
  <div class="card">
    <div class="innerCard">
      <div class="topSection">
        <p class="company">{{ header }}</p>
        <p class="jobTitle">{{ jobTitle }}</p>
      </div>
      <div class="middleSection">
        <p class="text">{{ text }}</p>
      </div>
      <div class="bottomSection">
        <p class="address">{{ address }}</p>
        <p class="longevity">{{ longevity }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AboutCard",
  props: {
    header: {
      Type: String,
      required: true,
    },
    jobTitle: {
      Type: String,
      required: true,
    },
    text: {
      Type: String,
      required: true,
    },
    address: {
      Type: String,
    },
    longevity: {
      Type: String,
    },
  },
};
</script>

<style scoped>
p {
  font-family: "Roboto", sans-serif;
  margin: 0px;
}
.card {
  background-color: #181818;
  border-radius: 10px;
  box-shadow: 0px 4px 15px 0px #00000026;
}
.address,
.jobTitle,
.longevity {
  color: #716e6e;
}
.topSection {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 10px;
}
.company {
  font-weight: 600;
}
.company,
.text {
  color: #ffffff;
}
.jobTitle {
  font-weight: 700;
  text-align: right;
}
.text {
  font-weight: 400;
  padding-bottom: 10px;
}
.address {
  padding-bottom: 3px;
}
.innerCard {
  text-align: left;
}
@media screen and (min-width: 601px) {
  .card {
    width: 540px;
  }
  .innerCard {
    padding: 20px;
  }
  .company {
    font-size: 18px;
  }
  .text,
  .address,
  .longevity {
    font-size: 14px;
  }
}

@media screen and (max-width: 600px), (max-height: 750px) {
  .card {
    width: 300px;
  }
  .innerCard {
    padding: 15px;
  }
  .company {
    font-size: 18px;
  }
  .text,
  .address,
  .longevity {
    font-size: 14px;
  }
}
</style>
