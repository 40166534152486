<template>
  <div class="cardGroup">
    <div :key="card.image" v-for="card in cards" class="oneCard">
      <PortfolioCard
        :header="card.header"
        :image="card.image"
        :icons="card.icons"
        :textList="card.textList"
        :textID="card.textId"
      />
    </div>
  </div>
</template>

<script>
import PortfolioCard from "../PortfolioCard/PortfolioCard.vue";

export default {
  name: "PortfolioCardGroup",
  props: {
    cards: {
      Type: Array,
      required: true,
    },
  },
  components: {
    PortfolioCard,
  },
};
</script>

<style scoped>
.cardGroup {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
.oneCard {
  padding: 10px 10px 10px 10px;
}
</style>
